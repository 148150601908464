<template>
    <router-view></router-view>
</template>


<script>

import './style.css'

export default {
  name: 'app',
  data () {
    return {}
  }
}
</script>

<style>
</style>
